.button {
  padding: 0.8rem 2.6rem !important;
}
.profileScreen {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profileSetting {
  width: 100%;
  justify-content: center;
  display: flex;
}

.userDetails {
  padding-bottom: 50px;
  margin-top: 20px;
}

/* .chatButton {
  height: 50px;
  position: fixed;
  max-width: calc(25rem - 40px);
  width: 100%;
  bottom: 10px;
}
.submit-btn button {
  background-color: #383e44 !important;
  padding: 5px;
}
.submit-btn button:hover {
  background-color: #1a1e21 !important;
} */

/* .profileSetting {
  width: 100%;
  justify-content: center;
  display: flex;
} */
/* .imageDisplay {
  height: 90px;
  width: 90px;
  text-align: center;
  border: 3px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  border-radius: 100px;
  overflow: hidden;
  padding: 10px;
} */
.imageDisplay {
  margin-top: 50px;
}
.imageSource {
  border-radius: 100px;
  height: 64px;
  width: 64px;
  overflow: hidden;
}
.onlinText {
  font-size: 16px;
  color: #029b15;
  font-weight: 500;
  padding: 10px 0 5px;
}
.userProfileText {
  font-size: 30px;
}
.detailText {
  font-weight: 500;
  font-size: 16px;
}
.detailList {
  text-align: left;
  font-size: 16px;
  padding-bottom: 10px;
}
.iconProfileHight {
  height: 15px;
}
.iconImages {
  padding-right: 15px;
  display: inline-block;
  width: 25px;
}
