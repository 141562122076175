.cs-button:hover:not(:disabled) {
  opacity: 1 !important;
}

.chatInButton {
  background: #000 !important;
  color: #fff !important;
  font-size: 11px !important;
}
.newTrip .cs-main-container {
  border: 0px !important;
  height: auto !important;
}

.newTrip
  .cs-main-container--responsive
  .cs-chat-container
  .cs-conversation-header
  .cs-conversation-header__actions
  .cs-button--info {
  display: block !important;
}

.multi_chat_container .cs-message--outgoing .cs-message__content {
  background-color: #726f6f !important;
  color: #fff !important;
}
.multi_chat_container .cs-message__content {
  background-color: #000000 !important;
  color: #fff !important;
}
.new_trip_msg .cs-message__content {
  color: #000 !important;
}

.new_trip_msg .cs-message-list__scroll-wrapper > .cs-message,
.new_trip_msg .cs-message-list__scroll-wrapper > .cs-message-group {
  max-width: 100% !important;
  width: 100%;
  cursor: pointer;
}

.buttonLocation {
  background: blue !important;
  padding: 10px 25px !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.new_trip_msg .cs-message__content {
  background-color: #ffffff !important;
}
.cs-conversation.cs-conversation--active {
  color: #000 !important;
}
