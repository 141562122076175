.chat-header {
  background-color: transparent !important;
}
.chat-header-content div:first-child {
  background-color: transparent !important;
}
/* .cs-conversation-list {
  overflow-y: scroll !important;
} */
.cs-message-list {
  overflow-y: scroll !important;
}
.cs-message-list .scrollbar-container {
  position: relative !important;
  height: auto !important;
  overscroll-behavior-y: none !important;
  overflow-anchor: auto !important;
  touch-action: auto !important;
}

::-webkit-scrollbar {
  width: 6px;
  border-radius: 6px;
  background-color: #f7fbff;
  right: 2px;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  background-color: #c6e3fa;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
}

.username {
  font-size: 16px;
}
.find-btn {
  background-color: #3a91af !important;
  color: #ffffff !important;
}

.location {
  font-size: 14px;
  word-break: break-all;
}
.map-container {
  height: 400px;
}

.pick-person {
  color: #bfccdf;
  margin-top: 5px;
}

.lock-div {
  background-color: #ebf1f7;
}
.send-me {
  color: #a0b3ce;
}
.location_form {
  min-width: 400px;
}

.button-width {
  width: auto !important;
  flex: 1 1;
  padding: 10px 10px !important;
}

.notification {
  z-index: 99999;
  right: 40px;
  top: 10px !important;
  padding: 5px !important;
  font-size: 14px !important;
}
.notification button {
  font-size: 10px !important;
  right: -30px !important;
  top: -19px !important;
}
.message_text_box div {
  background-color: transparent;
}

@media only screen and (max-width: 900px) {
  .location_form {
    width: inherit;
    min-width: auto;
  }
  .notification {
    z-index: 99999;
    right: auto;
    left: auto;
  }
}
/* .form-switch .form-check-input {
  width: 2.5em !important;
}
.form-check-input {
  height: 1.4em !important;
} */
