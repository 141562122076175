.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9ecef;
  color: #495057;
  min-width: 40px;
  height: 38px;
  text-align: center;
  border: 1px solid #ced4da;
}

/* Profile Page Css */
/* .profileScreen {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userDetails {
  padding-bottom: 50px;
}

.chatButton {
  height: 50px;
  position: fixed;
  max-width: calc(25rem - 40px);
  width: 100%;
  bottom: 10px;
}
.submit-btn button {
  background-color: #383e44 !important;
  padding: 5px;
}
.submit-btn button:hover {
  background-color: #1a1e21 !important;
}

.profileSetting {
  width: 100%;
  justify-content: center;
  display: flex;
}
.imageDisplay {
  height: 90px;
  width: 90px;
  text-align: center;
  border: 3px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  border-radius: 100px;
  overflow: hidden;
  padding: 10px;
}
.imageSource {
  border-radius: 100px;
  height: 64px;
  width: 64px;
  overflow: hidden;
}
.onlinText {
  font-size: 16px;
  color: #029b15;
  font-weight: 500;
  padding: 10px 0 5px;
}
.userProfileText {
  font-size: 30px;
}
.detailText {
  font-weight: 500;
  font-size: 16px;
}
.detailList {
  text-align: left;
  font-size: 16px;
  padding-bottom: 10px;
}
.iconProfileHight {
  height: 15px;
}
.iconImages {
  padding-right: 15px;
  display: inline-block;
  width: 40px;
} */

/* CHat with User */
.chatWithUser,
.newTrip {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headingText {
  font-size: 20px;
  text-align: left;
  font-weight: bold;
  margin: 10px 0 40px;
}
.chatWithUser .input-field,
.newTrip .input-field {
  height: 45px;
}
.chatWithUser .input-field::placeholder,
.newTrip .input-field::placeholder {
  color: black;
  font-weight: 500;
  font-size: 14px;
}
.chatWithUser .input-field:focus,
.newTrip .input-field:focus {
  border: 2px solid black;
}

.multi_chat_container {
  height: 100%;
}
#root,
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.newTrip .submit-btn .btn {
  height: 60px;
}

.copyRight {
  position: fixed;
  bottom: 10px;
  font-size: 14px;
  background-color: #fff;
}

.new_trip_data {
  width: 100%;
  max-width: 25rem;
  margin-top: 30px;
}
.custom_text {
  display: none;
}
.custom_button {
  display: block !important;
}
.display_hide_menu {
  display: none !important;
}
.multi_chat_container .submit-btn {
  padding: 10px;
}
.multi_chat_container .input-container {
  padding-left: 10px;
  padding-right: 10px;
}
.message_list button {
  width: 65px;
  padding: 2px 0px;
}
.spinner-container {
  position: absolute;
  z-index: 9;
  background: #221e1e70;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-time {
  margin-top: -2px !important;
}

.tab-div {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.tab-item {
  flex: 1;
  width: auto;
  padding: 10px 10px;
  border-bottom: 4px solid #999999ff;
  display: inline-block;
  color: #999999ff;
  cursor: pointer;
  text-align: -webkit-center;
  margin: 5px 2px;
  font-size: 14px;
}
.tab-item.active {
  border-color: #6ea9d7;
  color: #6ea9d7;
}

.chat-container {
  width: 100%;
}

.chat-container .cs-chat-container {
  width: 100%;
}
.new-status {
  border-bottom: 1px solid #999999ff;
}

.new-offer-div {
  width: 100%;
  display: flex;
  margin: 25px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.new-offer-div h5 {
  color: rgb(68, 82, 99);
  margin-bottom: 20px;
}

.offer-cancel-btn {
  padding: 15px !important;
  margin: 10px 0 !important;
  font-size: 16px !important;
}

.counter-btn {
  padding: 5px 5px !important;
  font-size: 12px !important;
  margin-right: 5px;
}

.accept-cancel-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 35px;
  height: 35px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #383636;
  border-radius: 100px;
  animation: spinner 1.5s linear infinite;
}

@media only screen and (max-width: 676px) {
  .multi_chat_container .cs-main-container--responsive .cs-sidebar--left {
    display: flex !important;
    position: fixed;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    min-width: 100% !important;
    transition: ease-in-out 0.2s;
    transform: translateX(0%);
  }

  .multi_chat_container .cs-main-container--responsive > .cs-chat-container {
    display: flex !important;
    position: fixed;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    min-width: 100% !important;
    transition: ease-in-out 0.2s;
    z-index: 9;
    transform: translateX(100%);
  }

  .multi_chat_container
    .cs-main-container--responsive.swap_screen
    > .cs-chat-container {
    transform: translate(0%);
  }

  .multi_chat_container
    .cs-main-container--responsive.swap_screen
    .cs-sidebar--left {
    transform: translateX(-100%);
  }
}
@media (min-width: 677px) and (max-width: 768px) {
  .custom_text_hide {
    display: none !important;
  }
  .custom_text {
    display: block;
  }
  .custom_button {
    display: none !important;
  }
}
@media (min-width: 577px) and (max-width: 676px) {
  .cs-main-container--responsive
    .cs-chat-container
    .cs-conversation-header
    .cs-conversation-header__back {
    display: block !important;
  }
}

@media only screen and (max-width: 400px) {
  .chatButton {
    right: 20px;
    max-width: calc(100% - 40px);
  }
}
@media only screen and (max-width: 678px) {
  .display_hide_menu {
    display: flex !important;
  }
  .counter-btn {
    line-height: 1.2 !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 768px) {
  .cs-main-container--responsive
    .cs-conversation-list
    .cs-conversation__content,
  .cs-main-container--responsive
    .cs-sidebar
    .cs-conversation-list
    .cs-conversation__content {
    display: flex !important;
  }
}
