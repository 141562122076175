.App {
  /* text-align: center; -------- comment on 19-7-2022 ---------- */
  height: 100%;
}

.mobContainer {
  /* box-shadow: 0px 0px 20px 0 #00000021; */
  height: 100%;
  /* width: 100%; -------- comment on 20-7-2022 because otp input width not set properly  */
  width: 100%;
  max-width: 25rem;
  margin: 0;
}

.card {
  border: none !important;
  padding: 0 20px;
}
.chatWithUser {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  padding: 0.81rem 0.96rem !important;
}
.mobile-input {
  height: 45px;
}
.mobile-input:focus {
  border-color: #ced4da !important;
  box-shadow: none !important;
}
.button {
  padding: 0.8rem 2.6rem !important;
}

/* .separator {
  width: 4px;
} */
.otp_form > div:first-child {
  display: flex;
  justify-content: space-between !important;
}
